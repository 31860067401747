import React, {useEffect, useState} from "react";
import WeatherMap from "../components/WeatherMap";
import * as MUi from '@material-ui/core'
import WeatherCompare from "../components/WeatherCompare";
import axios from 'axios'


const WeatherApiComparePage = ({}) => {

    const [token, setToken] = useState(null);
    const [chosenLocation, setChosenLocation] = useState([19.80805412808859, 80.20019531250001]);

    // useEffect(()=>{
    //     setToken("")
    //     axios.post('https://asia-south1-fishedge.cloudfunctions.net/generateIdTokenForUid', {"uid": "GFvc8hZADJOeFCul36Rd62ToHwI2"},
    //         {
    //             headers: {
    //                 'Authorization': `Basic ZmlzaGVkZ2V1c2VyOmRqaGJqVCZZKklCVXk=`
    //             }
    //         }).then(d=>console.log(d))
    //     // fetch('http://localhost:5000/fishedge/asia-south1/generateIdTokenForUid', {
    //     //     crossDomain:true,
    //     //     method: 'POST',
    //     //     withCredentials: true,
    //     //     headers: {
    //     //         'Content-Type':'application/json',
    //     //         'Authorization': 'Basic ZmlzaGVkZ2V1c2VyOmRqaGJqVCZZKklCVXk='
    //     //     },
    //     //     body: JSON.stringify({"uid": "GFvc8hZADJOeFCul36Rd62ToHwI2"})
    //     // }).then(r=>r.json())
    //     //     .then(data=>console.log(data))
    // },[])

    return (<div>
        <MUi.Grid container>
            <MUi.Grid item xs={6}>
                <WeatherMap setChosenLocation={setChosenLocation} chosenLocation={chosenLocation} />
            </MUi.Grid>
            <MUi.Grid item xs={6}>
                <MUi.Box style={{ maxHeight: '100vh', overflow: 'auto'}}>
                    <label>Token:</label>
                    <input type={'text'} placeholder={'Token'} value={token} onChange={(e)=>{setToken(e.target.value)}}/>
                    <WeatherCompare token={token} chosenLocation={chosenLocation} />
                </MUi.Box>
            </MUi.Grid>
        </MUi.Grid>
    </div>)
}

export default WeatherApiComparePage;