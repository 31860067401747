import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import React from "react";


const HopDetailItem = (props) => {
    return (
        <Container style={{color: '#540050'}}>
            <div style={{display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap', margin: 10}}>
                <Grid container>
                    <Grid item xs={2}>{props.iconComponent}</Grid>
                    <Grid item xs={1}><span style={{fontWeight: 100}}>|</span></Grid>
                    <Grid item xs={9}>{props.text}</Grid>
                </Grid>
            </div>
        </Container>
    )
}

export default HopDetailItem;