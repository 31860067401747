import Grid from "@material-ui/core/Grid";
import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import PrintIcon from '@material-ui/icons/Print';
import GetAppIcon from '@material-ui/icons/GetApp';
import {makeStyles} from "@material-ui/core";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    sectionMobile: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

const HopQrView = (props) => {

    const classes = useStyles();

    const [qrImage, setQrImage] = useState(null)

    useEffect(()=>{
        fetch(`http://34.93.222.231:9000/hop/generateQRCode?hopId=${props.data.internalId}`, {
            method: "GET",
            headers: {'Content-Type':'application/octet-stream'},
        })
            .then((result) => result.blob())
            .then(blob=>{
                setQrImage(URL.createObjectURL(blob))
            })
    }, [])

    function imageToPrintHtml(source)
    {
        return "<html><head><script>function step1(){\nsetTimeout('step2()', 10);}\nfunction step2(){window.print();window.close()}\n</script></head><body onload='step1()'>\n<img src='" + source + "' /></body></html>";
    }

    function printImage(source)
    {
        const aboutBlank = "about:blank";
        const pwa = window.open(aboutBlank, "_new");
        pwa.document.open();
        pwa.document.write(imageToPrintHtml(source));
        pwa.document.close();
    }

    const downloadQR = (source) => {
        const a = document.createElement('a');
        a.href = source;
        a.download = `QR_${props.data.internalId}.png`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }


    return (
        <div>
            <div className={classes.sectionDesktop}>
                <Grid container style={{maxWidth: 450}}>
                    <Grid item xs={6}>
                        <img width={200} style={{maxHeight:200}} src={qrImage} />
                        {/*<br/>*/}
                    </Grid>
                    <Grid item xs={6}>
                        <br/>
                        <br/>
                        <Button variant="outlined" style={{color: '#540050'}}
                                className={classes.button}
                                endIcon={<GetAppIcon />}
                                onClick={()=>{
                                    downloadQR(qrImage)
                                }}>Download QR code</Button>
                        <Button variant="outlined" style={{color: '#540050'}}
                                className={classes.button}
                                endIcon={<PrintIcon />}
                                onClick={()=>{
                                    printImage(qrImage)
                                }}>Print QR code</Button>
                    </Grid>
                    <div style={{paddingLeft: 20, fontSize: '15px', fontWeight: 500, textAlign: 'center', width: '100%'}}>Hop Id: {props.data.internalId}</div>
                </Grid>
            </div>
            <div className={classes.sectionMobile}>
                <Grid container style={{width: '100%'}}>
                    <div style={{paddingLeft: 20, fontSize: '15px', fontWeight: 500, textAlign: 'center', width: '100%'}}>
                        <span>Hop Id: {props.data.internalId}</span>
                        <br/>
                        <img width={200} style={{maxHeight:200}} src={qrImage} />
                        <br/>
                        {/*<Button variant="outlined" style={{color: '#540050'}}*/}
                        {/*        className={classes.button}*/}
                        {/*        endIcon={<GetAppIcon />}*/}
                        {/*        onClick={()=>{*/}
                        {/*            downloadQR(qrImage)*/}
                        {/*        }}>Download QR code</Button>*/}
                        {/*<br/>*/}
                        {/*<Button variant="outlined" style={{color: '#540050'}}*/}
                        {/*        className={classes.button}*/}
                        {/*        endIcon={<PrintIcon />}*/}
                        {/*        onClick={()=>{*/}
                        {/*            printImage(qrImage)*/}
                        {/*        }}>Print QR code</Button>*/}
                    </div>
                </Grid>
            </div>
        </div>
    )
}

export default HopQrView