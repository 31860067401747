import Typography from "@material-ui/core/Typography";
import HopDetailItem from "./HopDetailItem";
import PersonIcon from "@material-ui/icons/Person";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DirectionsBoatIcon from "@material-ui/icons/DirectionsBoat";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import HopQrView from "./HopQrView";
import * as L from 'leaflet'
import {makeStyles} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import sampleFish from '../images/fish_sample.jpg'
import HopSearchResult from "./HopSearchResult";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import GetAppIcon from "@material-ui/icons/GetApp";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    sectionMobile: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const HopSearchResults = (props) => {
    const classes = useStyles();


    const [activeHopId, setActiveHopId] = useState(null)

    const changeMapView = (searchResult) => {
        if(searchResult){
            props.setMapView(
                [searchResult.hopProperties.latitude, searchResult.hopProperties.longitude],
                12
            )
        }else{
            const pts = []
            props.searchResults.map(searchResult=>{
                pts.push(new L.marker([searchResult.hopProperties.latitude, searchResult.hopProperties.longitude]))
            })
            const group = new L.featureGroup(pts);
            props.setMapView(null, null, group.getBounds())
        }
    }


    const downloadQRs = () => {
        const ids = props.searchResults.map(e=>e['internalId'])
        fetch(`http://34.93.222.231:9000/hop/generateHopsQRCode`, {
            method: "POST",
            body: JSON.stringify({
                hop: ids
            }),
            headers: {'Content-Type':'application/json'},
        })
            .then((result) => result.blob())
            .then(blob=>{
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'fe_hop_qr_codes.zip';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
    }

    return (
        <div>
            <div className={classes.sectionDesktop} style={
                {
                    zIndex: 400,
                    position: 'absolute',
                    top: 80,
                    right: 10,
                    backgroundColor: '#fff',
                    overflowY: 'auto',
                    boxShadow: '3px 2px 8px 2px grey',
                    borderRadius: '5px',
                    maxHeight: '80vh'
                }
            }>
                {
                    props.searchResults.length>0?(
                        <div style={{textAlign: 'right'}}>
                            <Typography variant={'body2'} style={{padding: 10}}>
                        <span style={{float:'left'}}>
                            <b>Search results:</b> {props.searchResults.length}
                        </span>
                                <Button variant="outlined" style={{color: '#540050'}}
                                        className={classes.button}
                                        endIcon={<GetAppIcon />}
                                        onClick={()=>{
                                            downloadQRs()
                                        }}>Download QR codes</Button>


                            </Typography>
                        </div>
                    ):''
                }
                {
                    props.searchResults.map((searchResult, i) =>{
                        return (
                            <Accordion key={searchResult.internalId}
                                       onChange={(e, expanded)=>{
                                           if(expanded){
                                               setActiveHopId(searchResult.internalId)
                                               changeMapView(searchResult)
                                           }else{
                                               setActiveHopId(null)
                                               changeMapView(null)
                                           }
                                       }}
                                       expanded={activeHopId===searchResult.internalId}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography variant={'body2'} className={classes.heading}>{i+1}. {searchResult.internalId}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <HopSearchResult key={searchResult.id} data={searchResult} />
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                }
            </div>
            {/*<div className={classes.sectionMobile} style={*/}
            {/*    {*/}
            {/*    }*/}
            {/*}>*/}
            {/*    <div style={*/}
            {/*        {*/}
            {/*            zIndex: 400,*/}
            {/*            width: '100%',*/}
            {/*            height: 'auto',*/}
            {/*            maxHeight: '70%',*/}
            {/*            position: 'absolute',*/}
            {/*            bottom: 0,*/}
            {/*            right: 0,*/}
            {/*            backgroundColor: '#fff',*/}
            {/*            overflowY: 'auto',*/}
            {/*            paddingBottom: 20,*/}
            {/*            boxShadow: '3px 2px 8px 2px grey',*/}
            {/*            borderRadius: '5px'*/}
            {/*        }}>*/}
            {/*        <div onClick={()=>{*/}
            {/*            setShowBottomPanel(!showBottomPanel)*/}
            {/*        }}>*/}
            {/*            <img width={'100%'} style={{maxHeight:300}} src={sampleFish} />*/}
            {/*            <Typography variant="h5" style={{marginLeft: 25, marginTop: 10}}>*/}
            {/*                {hopData.fishType}Black Pomfret*/}
            {/*            </Typography>*/}
            {/*            <Typography variant="h6" component="h2" style={{marginLeft: 25}}>*/}
            {/*                {hopData.quantityInKg} Kg*/}
            {/*            </Typography>*/}
            {/*        </div>*/}

            {/*        <div*/}
            {/*            style={{display: showBottomPanel?'block':'none'}}*/}
            {/*        >*/}

            {/*            <HopDetailItem iconComponent={<PersonIcon/>} text={hopData.fishermanName} />*/}
            {/*            <HopDetailItem iconComponent={<LocationOnIcon/>} text={`${hopData.latitude.toFixed(2)}, ${hopData.longitude.toFixed(2)}`} />*/}
            {/*            <HopDetailItem iconComponent={<AccessTimeIcon/>} text={new Date(hopData.caughtOn).toLocaleString()} />*/}
            {/*            <HopDetailItem iconComponent={<DirectionsBoatIcon/>} text={"Dory"} />*/}
            {/*            <HopDetailItem iconComponent={<HomeWorkIcon/>} text={"Sassoon Dock"} />*/}

            {/*            <HopQrView data={props.data} />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default HopSearchResults