import React, {useState, useEffect} from "react";
import {MapContainer, Marker, Polyline, Popup, TileLayer, GeoJSON, useMap} from "react-leaflet"
import Grid from '@material-ui/core/Grid';
import HopDetails from "../components/HopDetails";

const HopViewer = (props) => {

    const id = props.match.params.id;
    const [isValid, setValid] = useState(Boolean(id))
    const [isLoading, setLoading] = useState(isValid)

    const [mapCenter, setMapCenter] = useState([21.67, 77.32])
    const [mapZoom, setMapZoom] = useState(5)
    const [lines, setLines] = useState([])

    const [hopData, setHopData] = useState(null)
    useEffect(()=>{
        fetch('http://34.93.222.231:9000/hop/get?hopId=' + id, {
            method: "GET"
        })
            .then(res=>res.json())
            .then(data=>{
                setLoading(false)
                if(data.error || !data.results){
                    setValid(false)
                }else{
                    setHopData(data.results)
                    setMapZoom(8)
                    setMapCenter([data.results.hopProperties.latitude, data.results.hopProperties.longitude])
                }
            })
    }, [])

    const { searchText, setSearchText } = useState('')

    return (
        <div>
            {
                isValid?(
                    isLoading?(
                        <div>Loading</div>
                    ): (
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <MapView lines={lines} hopData={hopData} mapCenter={mapCenter} mapZoom={mapZoom} />
                                {/*<AdvancedHopDetails data={hopData} />*/}
                                {hopData?<HopDetails data={hopData}/>:''}
                            </Grid>
                        </Grid>
                    )
                ):(
                    <div>Not found</div>
                )
            }
        </div>
    )
}
const MapView = (props) => {
    return <MapContainer style={{width: '100%', height: '100vh'}} center={props.mapCenter} zoom={props.mapZoom} >
        <ChangeView center={props.mapCenter} zoom={props.mapZoom} />
        <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url={'https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFuaW51bWVyOCIsImEiOiJja24wZXE4dGIwbXU4Mm9tZjhhcjh0NjdpIn0.KEWE9FRHQIt6_NogL4ZhjQ'}
        />
        {
            props.hopData?(
                <Marker position={[props.hopData.hopProperties.latitude, props.hopData.hopProperties.longitude]}/>
            ):''
        }
    </MapContainer>
}
function ChangeView({ center, zoom }) {
    const map = useMap();
    map.setMaxZoom(12);
    map.setView(center, zoom);
    return null;
}

export default HopViewer;