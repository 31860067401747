import React, {useState} from "react";
import {MapContainer, Marker, TileLayer, useMap} from "react-leaflet"
import Grid from '@material-ui/core/Grid';
import { Alert, AlertTitle } from '@material-ui/lab';
import SearchBar from "material-ui-search-bar";
import Snackbar from '@material-ui/core/Snackbar';
import HopSearchResult from "../components/HopSearchResult";
import HopSearchResults from "../components/HopSearchResults";
import * as L from 'leaflet'

const HopSearch = (props) => {

    const [searchText, setSearchText] = useState('')
    const [alert, setAlert] = useState({open: false, severity: '', text: ''})
    const [searchResults, setSearchResult] = useState([])

    const closeAlert = () => {
        setAlert({open: false, text: '', severity: ''})
    }

    const searchHop = () => {
        if(searchText.length<=0){
            setAlert({open: true, text: 'Invalid input', severity: 'error'})
            return
        }
        const searchHopIds = searchText.split(",")

        setSearchResult([])
        fetch('http://34.93.222.231:9000/hop/fetchHops', {
            method: "POST",
            body: JSON.stringify({
                "hop": searchHopIds
            }),
            headers: {'Content-Type':'application/json'},
        })
            .then(res=>res.json())
            .then(data=>{
                console.log(data)
                if (data.error || !data.results || data.results.length<=0){
                    setAlert({open: true, text: 'No data found for entered Hop Id', severity: 'error'})
                }else{
                    closeAlert()
                    setSearchResult(data.results)
                    const pts = []
                    data.results.map(searchResult=>{
                        pts.push(new L.marker([searchResult.hopProperties.latitude, searchResult.hopProperties.longitude]))
                    })
                    const group = new L.featureGroup(pts);
                    setMapView(null, null, group.getBounds())
                }
            })
    }

    const cancelHopSearch = () => {
        setSearchResult([])
        setDefaultMapView()
    }

    const [mapViewMode, setMapViewMode] = useState('center_zoom')
    const [mapCenter, setMapCenter] = useState([21.67, 77.32])
    const [mapZoom, setMapZoom] = useState(5)
    const [mapBounds, setMapBounds] = useState(new L.LatLngBounds([19.856457, 71.888104], [15.970182, 75.245488]))

    const setMapView = (c, z, bnds) => {
        console.log(c, z, bnds, mapViewMode)
        if(c!==null && z!==null){
            setMapViewMode('center_zoom')
            setMapCenter([...c]);
            setMapZoom(z)
        }else {
            setMapBounds(bnds)
            setMapViewMode('bounds')
            setMapBounds(bnds)
            // console.log(c, z, bnds, mapViewMode)
            // setTimeout(()=>{setMapBounds(bnds);}, 1000)
        }
    }

    const setDefaultMapView = () => {
        setMapViewMode('center_zoom')
        setMapCenter([21.67, 77.32]);
        setMapZoom(5)
    }

    return (
        <div>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <MapView mapViewMode={mapViewMode} mapBounds={mapBounds} mapCenter={mapCenter} mapZoom={mapZoom} searchResults={searchResults} />
                    <SearchBar
                        placeholder={'Enter Hop Ids...'}
                        style={{zIndex: 400, position: 'absolute', top: 80, left: 75}}
                        value={searchText}
                        onChange={(newValue) => setSearchText(newValue)}
                        onRequestSearch={searchHop}
                        onCancelSearch={cancelHopSearch}
                    />
                    <Snackbar open={alert.open}
                              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                              style={{zIndex: 400, marginTop: 125, marginLeft: 50}}>
                        <Alert onClose={() => {closeAlert()}} severity={alert.severity}>
                            <AlertTitle>{alert.text}</AlertTitle>
                        </Alert>
                    </Snackbar>

                    <HopSearchResults setMapView={setMapView} searchResults={searchResults} />

                </Grid>
            </Grid>
        </div>
    )
}

const MapView = (props) => {
    return <MapContainer style={{width: '100%', height: '100vh'}} center={props.mapCenter} zoom={props.mapZoom} >

        <ChangeBounds mapViewMode={props.mapViewMode} bounds={props.mapBounds} />
        <ChangeView mapViewMode={props.mapViewMode} center={props.mapCenter} zoom={props.mapZoom} />
        <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url={'https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFuaW51bWVyOCIsImEiOiJja24wZXE4dGIwbXU4Mm9tZjhhcjh0NjdpIn0.KEWE9FRHQIt6_NogL4ZhjQ'}
        />
        {
            props.searchResults.map(searchResult=><Marker position={[searchResult.hopProperties.latitude, searchResult.hopProperties.longitude]}/>)
        }
    </MapContainer>
}

function ChangeView({ mapViewMode, center, zoom }) {
    const map = useMap();
    map.setMaxZoom(12)
    if (zoom>12)
        zoom = 12
    if(mapViewMode==='center_zoom')
        map.setView(center, zoom);
    return null;
}

function ChangeBounds({ mapViewMode, bounds }) {
    const map = useMap();
    map.setMaxZoom(12)
    if(mapViewMode==='bounds')
        map.fitBounds(bounds)
    return null;
}

export default HopSearch;