import React from "react";
import {MapContainer, Marker, TileLayer, useMapEvent} from "react-leaflet";


const WeatherMap = ({setChosenLocation, chosenLocation}) => {
    return (
        <div>
            <MapView chosenLocation={chosenLocation} mapCenter={[21.67, 77.32]} mapZoom={5} setChosenLocation={setChosenLocation} />
        </div>
    )
}
function MapClick({setChosenLocation}) {
    useMapEvent('click', (e) => {
        setChosenLocation([e.latlng.lat, e.latlng.lng])
    })
    return null
}
const MapView = (props) => {
    return <MapContainer style={{width: '100%', height: '100vh'}} center={props.mapCenter} zoom={props.mapZoom} >
        <MapClick setChosenLocation={props.setChosenLocation} />
        <Marker position={props.chosenLocation} />
        <TileLayer
            url={'https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFuaW51bWVyOCIsImEiOiJja24wZXE4dGIwbXU4Mm9tZjhhcjh0NjdpIn0.KEWE9FRHQIt6_NogL4ZhjQ'}
        />
    </MapContainer>
}

export default WeatherMap;