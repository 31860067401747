import './App.css';
import { Route, Switch } from 'react-router-dom'
import HopViewer from "./pages/HopViewer";
import React from "react";
import HopSearch from "./pages/HopSearch";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import logo from './images/fe_ofish_logo.png'
import WeatherApiComparePage from "./pages/WeatherApiComparePage";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

function App() {
    const classes = useStyles();
    console.log(logo)
  return (
    <main>
        <AppBar position="static" style={{ background: '#540050' }}>
            <Toolbar>
                <img src={logo} width={40} alt="logo" className={classes.logo} />

                <Typography variant="subtitle2" className={classes.title} style={{marginLeft: 20}}>
                    Numer8's FE Hop
                </Typography>
            </Toolbar>
        </AppBar>
      <Switch>
          <Route path={'/weather-api-compare'} component={WeatherApiComparePage} exact/>
          <Route path={'/hop-search'} component={HopSearch} exact/>
          <Route path={'/hop-viewer/:id'} component={HopViewer} exact/>
          <Route path={'/'} component={HopViewer}/>
      </Switch>
    </main>
  );
}

export default App;
