import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyAkS3vQQqrgJOxHv7LNRUGKIOHdvXkTnrw",
    authDomain: "fishedge.firebaseapp.com",
    projectId: "fishedge",
    storageBucket: "fishedge.appspot.com",
    messagingSenderId: "190031383545",
    appId: "1:190031383545:web:da5bd5c23bdcb25d044b28",
    measurementId: "G-Q1D0HLSW3K"
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
export default firebaseApp;