import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import PersonIcon from '@material-ui/icons/Person';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import HopDetailItem from "./HopDetailItem";

import HopQrView from "./HopQrView";
import firebaseApp from "../firebase";
const useStyles = makeStyles((theme) => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    sectionMobile: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

const HopDetails = (props) => {
    const classes = useStyles();
    const hopData = props.data.hopProperties;

    const [fishPhoto, setFishPhoto] = useState(null)

    useEffect(()=>{
        const storage = firebaseApp.storage();
        const pathReference = storage.ref(hopData['fishPhotoUrl']);
        pathReference.getDownloadURL()
            .then(url=>{
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    const blob = xhr.response;
                    // console.log(URL.createObjectURL(blob))
                    setFishPhoto(URL.createObjectURL(blob))
                };
                xhr.open('GET', url);
                xhr.send();
            })
            .catch(err=>{
                console.log(err);
            })
    }, [])

    const [showBottomPanel, setShowBottomPanel] = useState(false)
    return (
        <div>
            <div className={classes.sectionDesktop} style={{zIndex: 400, position: 'absolute', top: 80, right: 10, backgroundColor: '#fff', overflowY: 'auto', paddingBottom: 20, boxShadow: '3px 2px 8px 2px grey', borderRadius: '5px'}}>
                <img width={450} style={{maxHeight:300}} src={fishPhoto} />
                <Typography variant="h5" style={{marginLeft: 25, marginTop: 10}}>
                    {hopData.fishType}
                </Typography>
                <Typography variant="h6" component="h2" style={{marginLeft: 25}}>
                    {hopData.quantityInKg} Kg
                </Typography>
                <HopDetailItem iconComponent={<PersonIcon/>} text={hopData.fishermanName} />
                <HopDetailItem iconComponent={<LocationOnIcon/>} text={`${hopData.latitude.toFixed(2)}, ${hopData.longitude.toFixed(2)}`} />
                <HopDetailItem iconComponent={<AccessTimeIcon/>} text={new Date(hopData.caughtOn).toLocaleString()} />
                <HopDetailItem iconComponent={<DirectionsBoatIcon/>} text={"Dory"} />
                <HopDetailItem iconComponent={<HomeWorkIcon/>} text={"Sassoon Dock"} />
                <HopQrView data={props.data} />
            </div>
            <div className={classes.sectionMobile} style={
                {
                }
            }>
                <div style={
                    {
                        zIndex: 400,
                        width: '100%',
                        height: 'auto',
                        maxHeight: '70%',
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        backgroundColor: '#fff',
                        overflowY: 'auto',
                        paddingBottom: 20,
                        boxShadow: '3px 2px 8px 2px grey',
                        borderRadius: '5px'
                    }}>
                    <div onClick={()=>{
                        setShowBottomPanel(!showBottomPanel)
                    }}>
                        <img width={'100%'} style={{maxHeight:300}} src={fishPhoto} />
                        <Typography variant="h5" style={{marginLeft: 25, marginTop: 10}}>
                            {hopData.fishType}
                        </Typography>
                        <Typography variant="h6" component="h2" style={{marginLeft: 25}}>
                            {hopData.quantityInKg} Kg
                        </Typography>
                    </div>

                    <div
                        style={{display: showBottomPanel?'block':'none'}}
                    >

                        <HopDetailItem iconComponent={<PersonIcon/>} text={hopData.fishermanName} />
                        <HopDetailItem iconComponent={<LocationOnIcon/>} text={`${hopData.latitude.toFixed(2)}, ${hopData.longitude.toFixed(2)}`} />
                        <HopDetailItem iconComponent={<AccessTimeIcon/>} text={new Date(hopData.caughtOn).toLocaleString()} />
                        <HopDetailItem iconComponent={<DirectionsBoatIcon/>} text={hopData.vesselName} />
                        <HopDetailItem iconComponent={<HomeWorkIcon/>} text={"Sassoon Dock"} />

                        <HopQrView data={props.data} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HopDetails